// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

@import '~leaflet/dist/leaflet.css';
@import '~esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

@import '~bootstrap-daterangepicker/daterangepicker.css';

@import './component';