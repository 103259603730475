footer {
    background-color: #181818;
    color: #ffffff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 20px 0px 20px 0px;
    .footer-social-media-wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .list-social-media {
            list-style: none;
            padding-left: 0;
            display: flex;
            justify-content: space-around;
            margin-bottom: 0;
            a {
                color: #ffffff;
            }
            i {
                font-size: 30px;
            }
            .item {
                padding: 10px 30px;
            }
        }
    }
}