// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


@font-face {
    font-family: 'CenturyGothic';
    src:  url('../font/GOTHIC.ttf');
  }
  
  @font-face {
    font-family: 'CenturyGothic';
    src:  url('../font/GOTHICB.ttf');
    font-weight: bold;
  }
  @font-face {
    font-family: 'Century Gothic Bold';
    src:  url('../font/GOTHICB.ttf');
  }
  
  @font-face {
    font-family: 'CenturyGothic';
    src:  url('../font/GOTHICI.ttf');
    font-style: italic;
  }
  
  @font-face {
    font-family: 'CenturyGothic';
    src:  url('../font/GOTHICBI.ttf');
    font-weight: bold;
    font-style: italic;
  }