body {
    font-family: 'CenturyGothic',sans-serif;
    font-weight: normal;
    font-size: 16px;
}
.dashed {
    border-top: 1px dashed ;
}

.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #181818;
    padding: 10px 20px;
    min-height: 30px;
    background-color: transparent;
    color: inherit;
    transition: all .3s ease;
    color: #181818;
    &:focus {
        box-shadow: none;
        color: #181818;
        outline: 0;
        border-color: #181818;
    }
    &:invalid, &:valid {
        &:focus {
            box-shadow: none !important;
            border-color: #181818;
        }
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #181818;
        opacity: 1; /* Firefox */
      }
      
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #181818;
      }
      
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: #181818;
    }
}