.wrap-box-image-upload {
    padding: 10px;
    @media (min-width: 992px) {
        max-width: 300px;
    }
}

/* Style the form */
label {
  font-size: 14px;
}
.form-check-input[type=radio] {
  padding: 5px;
}
.input-group-text {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
}
  
  /* Style the input fields */
  input {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
  }
  
  /* Mark input boxes that gets an error on validation: */
  input.invalid {
    background-color: #ffdddd;
  }
  
  /* Hide all steps by default: */
  .tab {
    display: none;
  }
  
  /* Make circles that indicate the steps of the form: */
  .step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
  }
  
  /* Mark the active step: */
  .step.active {
    opacity: 1;
  }
  
  /* Mark the steps that are finished and valid: */
  .step.finish {
    background-color: #212529;
  }

  .overlay-loading {
    // display: none;
    position: absolute;
    background-color: #ffffffbd;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.show {
      display: block;
    }
  }